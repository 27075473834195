import { Application } from '@hotwired/stimulus'
window.Stimulus = Application.start();

import Theme from './theme-selector'
import History from './history'
import DexSelector from './dex-selector'

Stimulus.register('theme', Theme);
Stimulus.register('history', History);
Stimulus.register('dex-selector', DexSelector);
