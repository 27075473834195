import { Controller } from "@hotwired/stimulus"
import { DEXES } from "./constants"
import { render } from 'pug'

export default class extends Controller {
  static targets = [
    'dexSwitcher'
  ]

  connect() {
    window.selectedDex = localStorage.getItem('selectedDex')

    if(window.selectedDex == undefined) {
      window.selectedDex = Object.keys(DEXES)[0]
    }

    this.drawDexSelector()
  }

  // changeDex(event) {
  //   const dex = event.params.dex

  //   if(Object.keys(DEXES).includes(dex)) {
  //     this.element.querySelectorAll('.button.dex').forEach(but => {
  //       but.classList.remove('selected')
  //     })

  //     event.target.classList.add('selected')

  //     localStorage.setItem('selectedDex', dex)
  //     window.selectedDex = dex

  //     // Clear out form, start fresh?
  //   }
  // }

  drawDexSelector() {
    this.element.innerHTML = ''

    Object.entries(DEXES).forEach(([name,url]) => {
      this.element.insertAdjacentHTML('beforeend', `
        <a class="button dex ${name == window.selectedDex ? 'selected' : ''}" href="#" data-action="dex-selector#changeDex" data-dex-selector-dex-param="${name}">
          ${name}
        </a>`
      )
    })
  }
}

